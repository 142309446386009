import React from 'react';
import Hero from '../components/Hero';
import backgroundImage from '../assets/images/image_14.png';
import ServiceOverview from '../components/ServiceOverview';
import LogoBanner from '../components/Banner';

function HomePage() {
  return (
    <div>
      <Hero 
        backgroundImage={backgroundImage}
        title="General Glass & Window, Inc."
        subtitle="Providing quality windows and doors for over 40 years in South Florida."
        ctaText="Request a Quote"
        ctaLink="/contact"
      />
      <ServiceOverview> </ServiceOverview>
      <LogoBanner></LogoBanner>
    </div>
  );
}

export default HomePage;