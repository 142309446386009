import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 0 2rem;
`;

const HeroContent = styled.div`
  max-width: 800px;
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  padding: 2rem;
  border-radius: 8px;
`;

const HeroTitle = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const HeroSubtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #2a77f1;
  color: white;
  text-decoration: none;
  font-size: 1.25rem;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #225fcc;
  }
`;

function Hero({ backgroundImage, title, subtitle, ctaText, ctaLink }) {
  return (
    <HeroContainer backgroundImage={backgroundImage}>
      <HeroContent>
        <HeroTitle>{title}</HeroTitle>
        {subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
        {ctaText && <CTAButton href={ctaLink}>{ctaText}</CTAButton>}
      </HeroContent>
    </HeroContainer>
  );
}

export default Hero;