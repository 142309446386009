import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;  /* Adjust as needed for horizontal padding */
  height: 100px;  /* Set a fixed height to the navbar */
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const NavbarLogo = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

const LogoImage = styled.img`
  height: 100px;  
  max-width: 100%;
  object-fit: contain;
  box-shadow: none;
`;

const NavbarLinks = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;  /* Center vertically */
`;

const NavbarLinkItem = styled.li`
  margin: 0 1rem;
`;

const NavbarLink = styled(Link)`
  text-decoration: none;
  color: #2e2e2e;
`;

function Navbar({ logoSrc }) {
  return (
    <NavbarContainer>
      <NavbarLogo to="/">
        {logoSrc ? <LogoImage src={logoSrc} alt="Logo" /> : "General Glass and Window"}
      </NavbarLogo>

      <NavbarLinks>
        <NavbarLinkItem>
          <NavbarLink to="/">Home</NavbarLink>
        </NavbarLinkItem>
        <NavbarLinkItem>
          <NavbarLink to="/gallery">Gallery</NavbarLink>
        </NavbarLinkItem>
        <NavbarLinkItem>
          <NavbarLink to="/contact">Contact</NavbarLink>
        </NavbarLinkItem>
      </NavbarLinks>
    </NavbarContainer>
  );
}

export default Navbar;