import React from 'react';
import styled from 'styled-components';
import Gallery from '../components/Gallery';
import image_1 from '../assets/images/image_1.png';
import image_2 from '../assets/images/image_2.png';
import image_3 from '../assets/images/image_3.png';
import image_6 from '../assets/images/image_6.png';
import image_7 from '../assets/images/image_7.png';
import image_8 from '../assets/images/image_8.png';
import image_9 from '../assets/images/image_9.png';
import image_10 from '../assets/images/image_10.png';
import image_11 from '../assets/images/image_11.png';
import image_12 from '../assets/images/image_12.png';
import image_13 from '../assets/images/image_13.png';
import image_15 from '../assets/images/image_15.png';
import image_16 from '../assets/images/image_16.png';

import before_after1 from '../assets/images/before_after1.png';

const residentialImages = [
  {
    src: image_1,
    description: ''
  },
  {
    src: image_2,
    description: ''
  },
  {
    src: before_after1,
    description: 'Before (left) and After (right)'
  },
  {
    src: image_3,
    description: ''
  },
  {
    src: image_6,
    description: ''
  },
  {
    src: image_7,
    description: ''
  },
  {
    src: image_8,
    description: ''
  },
  {
    src: image_9,
    description: ''
  },
  {
    src: image_10,
    description: ''
  },
  {
    src: image_8,
    description: ''
  },
  {
    src: image_15,
    description: ''
  },
  {
    src: image_16,
    description: ''
  }
];

const commercialImages = [
  {
    src: image_11,
    description: ''
  },
  {
    src: image_12,
    description: ''
  },
  {
    src: image_13,
    description: ''
  },
]

const PageContainer = styled.div`
  padding: 2rem;
`;

const PageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  font-size: 2.0rem;
  color: #2e2e2e;
  font-weight: bold;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #2e2e2e; /* Line color */
    margin: 0 1rem;
  }
`;

const TitleText = styled.h1`
  font-size: 1.5rem;
  color: #2e2e2e;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

function GalleryPage() {
  return (
    <PageContainer>
      <PageTitle>Our Recent Projects</PageTitle>
      <SectionTitle>
        <TitleText>Residential</TitleText>
      </SectionTitle>
        <Gallery images={residentialImages} />
      <SectionTitle>
        <TitleText>Commercial</TitleText>
      </SectionTitle>
        <Gallery images={commercialImages} />
    </PageContainer>
  );
}

export default GalleryPage;