import React, { useState } from 'react';
import styled from 'styled-components';

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const GalleryItem = styled.div`
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  padding-bottom: 75%; /* Aspect ratio (height/width). Adjust as needed */
`;

const GalleryImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the entire container */
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
`;

const LightboxOverlay = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 2rem;
  flex-direction: column;
  text-align: center;
`;

const LightboxImage = styled.img`
  max-width: 90%;
  max-height: 70%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  margin-bottom: 1rem;
`;

const LightboxDescription = styled.p`
  color: white;
  font-size: 1.25rem;
  margin-top: 1rem;
`;

function Gallery({ images }) {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState("");

  const openLightbox = (image, description) => {
    setSelectedImage(image);
    setSelectedDescription(description);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setSelectedImage(null);
    setSelectedDescription("");
  };

  return (
    <>
      <GalleryGrid>
        {images.map((item, index) => (
          <GalleryItem key={index}>
            <GalleryImage
              src={item.src}
              alt={`Project ${index + 1}`}
              onClick={() => openLightbox(item.src, item.description)}
            />
          </GalleryItem>
        ))}
      </GalleryGrid>

      {selectedImage && (
        <LightboxOverlay isOpen={lightboxOpen} onClick={closeLightbox}>
          <LightboxImage src={selectedImage} alt="Enlarged view" />
          {selectedDescription && <LightboxDescription>{selectedDescription}</LightboxDescription>}
        </LightboxOverlay>
      )}
    </>
  );
}

export default Gallery;