import React from 'react';
import Hero from '../components/Hero';
import ContactForm from '../components/ContactForm';
import backgroundImage from '../assets/images/image_2.png'

function ContactPage() {
    return (
    <div>
        <Hero 
        backgroundImage={backgroundImage}
        title="Request a Quote"
        />
        <ContactForm />
    </div>
    );
}

export default ContactPage;