import React from 'react';
import styled from 'styled-components';
import bannerImage from '../assets/images/manufLogos.png';

const BannerContainer = styled.div`
  width: 100%;
  padding: 1rem 0;
  text-align: center;
  background-color: #fefefe; /* Optional: Light background to distinguish the section */
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;

  &::before,
  &::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #2e2e2e; /* Line color */
    margin: 0 1rem;
  }
`;

const TitleText = styled.h2`
  font-size: 1.5rem;
  color: #2e2e2e; 
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

const BannerImage = styled.img`
  width: 100%;
  max-width: 1200px; /* Adjust to fit your design */
  height: auto;
`;

function LogoBanner({ bannerSrc }) {
  return (
    <BannerContainer>
      <SectionTitle>
        <TitleText>We Only Use The Best</TitleText>
      </SectionTitle>
      <BannerImage src={bannerImage} alt="Our Partners" />
    </BannerContainer>
  );
}

export default LogoBanner;