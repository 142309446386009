import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background-color: #FEFEFE;
  color: white;
  padding: 2rem;
  text-align: center;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterText = styled.p`
  margin: 0;
  color: #2e2e2e;
  font-size: 1rem;
`;

function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterText>© {new Date().getFullYear()} General Glass and Window. All rights reserved.</FooterText>
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;